import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../alert/alert.service';
import { CoreUtilUrl } from '../util/Url';
import { CoreAuthenticationService } from './CoreAuthenticationService';

@Injectable()
export class CoreAuthGuard  {

    constructor(
        private router: Router,
        private authenticationService: CoreAuthenticationService,
        private alertService: AlertService,
        private translateService: TranslateService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const auth = this.authenticationService.getAuthentication();
        if (auth && auth.user) {
            let partyId = parseInt(route.queryParams['party']);
            if (!partyId) {
                // not required to switch party
                return true;
            }
            if (auth.party && auth.party.id === partyId) {
                // same party
                return true;
            }
            this.authenticationService.switchParty(partyId).subscribe({
                next: (response) => {
                    if (response.party.id !== partyId) {
                        this.alertService.error('error.noPartyAccess');
                        CoreUtilUrl.navigateWithReturnUrl(this.router, '/user/welcome', state.url);
                        return false;
                    }
                    this.alertService.info(this.translateService.instant('field.switchToParty.label') + " " + response.party.name);
                    return true;
                },
                error: (error) => {
                    this.alertService.error('error.noPartyAccess');
                    CoreUtilUrl.navigateWithReturnUrl(this.router, '/user/welcome', state.url);
                    return false;
                }
            });
        }
        // not logged in so redirect to login page with the return url and return false)
        CoreUtilUrl.navigateWithReturnUrl(this.router, '/user/login', state.url);
        return false;
    }



}