import { Component, forwardRef, Input } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';

const noop = () => {
	// This is intentional
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => WeekdaysInputComponent),
	multi: true
};
export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => WeekdaysInputComponent),
	multi: true
};


@Component({
	selector: 'app-weekdays-input',
	templateUrl: './WeekdaysInputComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class WeekdaysInputComponent {

	public days: string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

	@Input()
	required = false;

	@Input()
	disabled = false;

	@Input()
	label: string;

	@Input()
	helpUri: string;

	public defaultValue = '-------';

	toggleWeekday(index: number) {
		if (this.value == null) {
			this.value = this.defaultValue;
		}
		let current = this.value.substr(index, 1);
		current = current !== '-' ? '-' : this.days[index].substr(0, 1);
		this.value = this.value.substr(0, index) + current + this.value.substr(index + 1);
	}

	getWeekday(index: number) {
		if (this.value != null) {
			return this.value.substr(index, 1);
		}
		return '-';
	}

	// The internal data model
	private innerValue: string;

	public errorStateMatcher = new SimpleErrorStateMatcher();

	public setNull(e: MatCheckboxChange) {
		if (!e.checked) {
			this.value = null;
		}
	}

	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	// get accessor
	get value(): any {
		return this.innerValue;
	};

	// returns null when valid else the validation object
	// in this case we're checking if the json parsing has
	// passed or failed from the onChange method
	public validate(c: UntypedFormControl) {
		if ((this.value == null || this.value.length < 7) && !this.required) {
			return null;
		}
		if (this.value == null) {
			return {
				jsonParseError: {
					valid: false,
				},
			};
		}
		if (this.value === this.defaultValue && this.required) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				jsonParseError: {
					valid: false,
				},
			};
		}
		return null;
	}

	// set accessor including call the onchange callback
	set value(v: any) {
		if (v !== this.innerValue) {
			this.innerValue = v;
			this.validate(null);
			this.onChangeCallback(v);
		}
	}

	// From ControlValueAccessor interface
	writeValue(value: any) {
		if (value !== this.innerValue) {
			this.innerValue = value;
			this.validate(null);
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}
	constructor(
	) {
	}

}
