import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
	selector: 'app-core-captcha-image',
	templateUrl: './CoreCaptchaImageComponent.html'
})

export class CoreCaptchaImageComponent implements OnInit, OnDestroy {

	counter: number;
	public now: number = new Date().getTime();
	interval: any;
	ngOnInit() {
		this.counter = 0;
		this.interval = setInterval(() => {
			if (this.counter >= 100) {
				this.now = new Date().getTime();
				this.counter = 0;
			} else {
				this.counter = this.counter + 2;
			}
		}, 2000);
	}
	ngOnDestroy() {
		clearInterval(this.interval);
	}
}