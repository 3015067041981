<google-map *ngIf="mapVendor===1 && latitude && longitude" (mapInitialized)="onMapReady($event)" width=100%
    [zoom]="zoom" [options]="options">
    <map-advanced-marker *ngFor="let marker of markers;let index = index; trackBy:itemIdentity"
        [position]="{ lat: marker.latitude, lng: marker.longitude }" [gmpDraggable]="marker.markerDraggable"
        (mapDragend)="marker.onDragEnd($event)" (mapClick)="marker.onMarkerClick()" [title]="marker.title"
        [fitBounds]="fitBounds" [options]="marker.options" [content]="marker.content">
    </map-advanced-marker>
    <map-polyline *ngFor="let polyline of polylines" [options]="polyline.options">
    </map-polyline>
</google-map>
<abm-map #map *ngIf="mapVendor===2" (ready)="onBaiduMapReady($event)">
</abm-map>