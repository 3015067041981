import { ComponentRef, ElementRef, Injectable, Injector, createComponent, inject, EnvironmentInjector } from '@angular/core';
import { OnMount } from './DynamicHtmlInterface';
import { DynamicHtmlOptions } from './DynamicHtmlOptions';

export interface DynamicHTMLRef {
  check: () => void;
  destroy: () => void;
}

function isBrowserPlatform() {
  return typeof window !== 'undefined' && typeof document !== 'undefined';
}

@Injectable({ providedIn: 'root' })
export class DynamicHtmlRenderer {

  private componentRefs = new Map<ElementRef, Array<ComponentRef<any>>>();
  private injector = inject(Injector);
  private environmentInjector = inject(EnvironmentInjector);

  constructor(private options: DynamicHtmlOptions) { }

  renderInnerHTML(elementRef: ElementRef, html: string): DynamicHTMLRef {
    if (!isBrowserPlatform()) {
      return {
        check: () => { },
        destroy: () => { },
      };
    }

    elementRef.nativeElement.innerHTML = html;
    const componentRefs: Array<ComponentRef<any>> = [];

    this.options.components.forEach(({ selector, component }) => {
      const elements = elementRef.nativeElement.querySelectorAll(selector);
      elements.forEach((el: Element) => {
        const content = el.innerHTML;
        const cmpRef = createComponent(component, {
          environmentInjector: this.environmentInjector,
          hostElement: el
        });

        el.removeAttribute('ng-version');

        if ('dynamicOnMount' in cmpRef.instance) {
          const attrsMap = new Map<string, string>();
          Array.from(el.attributes).forEach(attr => attrsMap.set(attr.name, attr.value));
          (cmpRef.instance as OnMount).dynamicOnMount(attrsMap, content, el);
        }

        componentRefs.push(cmpRef);
      });
    });

    this.componentRefs.set(elementRef, componentRefs);
    return {
      check: () => componentRefs.forEach(ref => ref.changeDetectorRef.detectChanges()),
      destroy: () => {
        componentRefs.forEach(ref => ref.destroy());
        this.componentRefs.delete(elementRef);
      },
    };
  }
}
