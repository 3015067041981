import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { AlertService } from "app/core/alert/alert.service";
import { CoreAuthenticationService } from "app/core/authentication/CoreAuthenticationService";
import { PartyPlaceMergeModel } from "app/generated/backend/yard/api/party-place-merge-model";
import { PartyPlaceMergeHandlerService } from "app/generated/backend/yard/service/party-place-merge-handler";
import { Subscription } from "rxjs";

export interface PartyPlaceMergeDialogData {
  partyPlaceId: number;
  isStoreLocation: boolean;
}

@Component({
  templateUrl: './PartyPlaceMergeDialog.html'
})
export class PartyPlaceMergeDialog implements OnDestroy, OnInit {
  public loading = false;
  private _subscriptions: Subscription[] = new Array<Subscription>();
  public partyPlaceId: number;
  public isStoreLocation: boolean = false;
  public mergePartyPlaceId: number;

  constructor(
    public service: PartyPlaceMergeHandlerService,
    public alertService: AlertService,
    public authenticationService: CoreAuthenticationService,
    public dialogRef: MatDialogRef<PartyPlaceMergeDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: PartyPlaceMergeDialogData,
  ) {
    this.partyPlaceId = data.partyPlaceId;
    this.isStoreLocation = data.isStoreLocation;
  }
  ngOnInit() {

  }

  onSave() {

    this.loading = this.alertService.start();
    const partyId = this.authenticationService.getPartyId();
    const request = new PartyPlaceMergeModel();
    request.mergePartyPlaceId = this.mergePartyPlaceId;
    request.id = this.partyPlaceId;
    this._subscriptions.push(this.service.put(partyId, this.partyPlaceId, request).subscribe(
      {
        next: () => {
          this.loading = this.alertService.success();
          this.dialogRef.close(request);
        },
        error: error => {

          this.loading = this.alertService.error(error);
        }
      }));

  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
