import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, ValidationErrors, Validator } from '@angular/forms';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
import { Observable, Subscription } from 'rxjs';

const noop = () => {
	// This is intentional
};

export interface BaseEnumClass<T> {
	transform(value: T, suffix: string): Observable<string>;
}

@Directive()
export class BaseEnumRadioGroupComponent<T> implements ControlValueAccessor, Validator {

	@Input()
	public required: boolean = false;

	@Input()
	public disabled: boolean = false;

	@Input()
	public addNew: boolean = false;

	@Input()
	public maxOptions: number;

	@Input()
	public label: string;

	@Input()
	public iconClass: string;

	@Input()
	public placeholder: string = '';

	@Input()
	public helpUri: string;

	@Input()
	public hint: string;

	@Output()
	itemSelected: EventEmitter<T> = new EventEmitter<T>();

	@Output() blur = new EventEmitter<number>();

	// public loading: boolean = true;
	private _innerValue: T;
	public model: string = '';
	// public allItems: T[];
	protected subscriptions = new Array<Subscription>();
	public errorStateMatcher = new SimpleErrorStateMatcher();
	private currentIndex = null;
	public currentSelectedId = null;

	// Placeholders for the callbacks which are later providesd
	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	// get accessor
	get value(): T {
		return this._innerValue;
	};

	// set accessor including call the onchange callback
	set value(id: T) {
		if (id !== this._innerValue) {
			this._innerValue = id;
			this.selectItem(id);
			this.onChangeCallback(id);
		}
	}

	// From ControlValueAccessor interface
	writeValue(id: T) {
		if (id !== this._innerValue) {
			this._innerValue = id;
			this.selectItem(id);
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	public validate(c: UntypedFormControl): ValidationErrors | null {
		this.errorStateMatcher.valid = true;
		if (this.value == null && this.required) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				required: true
			};
		}
		return null;
	}

	toDisplayText(item: T): string {
		let displayText = this._textByValue.get(item);
		if (displayText) {
			return displayText;
		}
		return null;
	}

	toTitleText(item: T): string {
		return this.toDisplayText(item);
	}

	toOptionText(item: T): string {
		return this.toDisplayText(item);
	}

	getOptionValue(option: T): T {
		return option;
	}

	onItemSelected(option: MatRadioChange) {
		this.selectItem(option.value);
		this.allItems.forEach(eachItem => {
			if (eachItem === option.value) {
				this.itemSelected.emit(eachItem);
				this.blur.emit(null);
			}
		});
	}

	selectItem(option: T) {
		if (this.allItems) {
			this.value = option;
			this.model = null;
			this.allItems.forEach(eachItem => {
				if (eachItem === this.value) {
					this.model = this.toDisplayText(eachItem);
				}
			});
		}
		this.validate(null);
	}

	onBlur() {
		this.blur.emit(null);
	}

	private _textByValue = new Map<T, string>();
	private _valueByText = new Map<string, T>();

	constructor(
		public allItems: T[],
		private source: BaseEnumClass<T>
	) {
		allItems.forEach(item => {
			this.source.transform(item, 'label').subscribe(text => {
				this._textByValue.set(item, text);
				this._valueByText.set(text, item);
			});
		});
	}

	closeOption() {
		this.currentIndex = null;
		this.currentSelectedId = null;
		this.validate(null);
		this.onChangeCallback(this._innerValue);
	}

	onClear() {
		this.value = null;
		this.model = '';
		this.currentIndex = null;
		this.currentSelectedId = null;
	}

	onKeyDownEvent(event: any) {
		if (event.code === 'ArrowDown' || event.code === 'ArrowUp'
			|| event.code === 'Enter' || event.code === 'NumpadEnter'
			|| event.code === 'Tab' || event.code === 'ShiftLeft' || event.code === 'ShiftRight'
			|| event.code === 'Backspace' || event.code === 'Delete'
			|| event.code === 'Home') {
			if (event.code === 'ArrowDown') {
				if (this.currentIndex === null) {
					this.currentIndex = 0;
				} else if (this.allItems.length && this.allItems.length - 1 > this.currentIndex) {
					this.currentIndex = this.currentIndex + 1;
				}
			} else if (event.code === 'ArrowUp') {
				if (this.currentIndex > 0) {
					this.currentIndex = this.currentIndex - 1;
				}
			}
			if (this.currentIndex !== null) {
				if ((event.code === 'Enter' || event.code === 'NumpadEnter' || event.code === 'Tab')) {
					this.selectItem(this.allItems[this.currentIndex]);
					this.itemSelected.emit(this.allItems[this.currentIndex]);
				}
				this.currentSelectedId = this.allItems[this.currentIndex];
				const element = document.getElementById(this.currentSelectedId);
				if (element) {
					element.scrollIntoView({ block: 'nearest' });
				}
			}
		} else {
			event.preventDefault();
		}
	}
}
