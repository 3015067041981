import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { BookingCargoGroupsComponent } from 'app/booking/cargo-groups/BookingCargoGroupsComponent';
import { CoreButtonModule } from 'app/core/button/CoreButtonModule';
import { ContactUsComponent } from 'app/core/contact-us/ContactUsComponent';
import { RouteTransformerDirective } from 'app/core/content/RouteTransformerDirective';
import { RailMapComponent } from 'app/core/rail-map/RailMapComponent';
import { ContentTranslationHandlerModule } from 'app/generated/backend/content/service/content-translation-handler';
import { GlossaryTagComponent } from 'app/glossary/GlossaryTagComponent';
import { CoreContentComponent } from './CoreContentComponent';
import { DynamicHtmlModule } from './dynamic-html-content/DynamicHtmlModule';

@NgModule({
	imports: [
		CommonModule,
		CoreButtonModule,
		DynamicHtmlModule.forRoot({
			components: [
				{ component: BookingCargoGroupsComponent, selector: 'app-booking-cargo-groups' },
				{ component: ContactUsComponent, selector: 'app-contact-us' },
				{ component: GlossaryTagComponent, selector: 'app-glossary-tag' },
				{ component: RailMapComponent, selector: 'app-rail-map' }
			]
		}),
		MatCardModule,
		ContentTranslationHandlerModule
	],
	declarations: [
		CoreContentComponent, RouteTransformerDirective
	],
	exports: [
		CoreContentComponent
	]
})
export class CoreContentModule { }
