import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { CoreCustomizationService } from '../customization/CoreCustomizationService';


@Component({
	selector: 'app-help-popup-dialog',
	templateUrl: './help-popup-dialog.component.html'
})

export class HelpPopupDialogComponent implements OnInit, OnDestroy {

	public uri: string;

	private subscriptions: Subscription[] = new Array<Subscription>();

	public constructor(
		protected customizationService: CoreCustomizationService,
		private dialogRef: MatDialogRef<HelpPopupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data
	) {
		this.uri = data.uri;
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => {
			sub.unsubscribe();
		});
	}

	close() {
		this.dialogRef.close();
	}
}
