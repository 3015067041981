import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TopicInfoModel } from 'app/generated/backend/content/api/topic-info-model';


@Component({
	templateUrl: './GlossaryPopupComponent.html',
	styleUrls: ['GlossaryComponent.scss']
})

export class GlossaryPopupComponent {

	public loading: boolean = true;
	public uri: string;
	public item: TopicInfoModel;

	public constructor(
		private router: Router,
		private dialogRef: MatDialogRef<GlossaryPopupComponent>,
		@Inject(MAT_DIALOG_DATA) data
	) {
		this.uri = data.uri;
		this.item = data.data;
	}

	onCancel() {
		this.dialogRef.close(false);
	}

	onGoToContent() {
		this.router.navigate([this.item.contentUri]);
		this.dialogRef.close(true);
	}

}
